import "./App.css";

function App() {
  return (<div className="App">
    <div className="header navbar bg-base-100">
      <div className="flex-1">
        <a className="btn btn-ghost text-xl">
          <img className="h-12" src="/edrugslablogo.png" alt="" />
          {/* eDrugs Lab */}
        </a>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          <li><a>Connect wallet</a></li>
          <li>
            <details>
              <summary>
                Socials
              </summary>
              <ul className="p-2 bg-base-100 rounded-t-none">
                <li><a>Telegram</a></li>
                <li><a>Twitter</a></li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
    <div className="landing flex flex-col justify-center items-center">
      {/* <img className="w-full absolute left-0" src="/landing.gif" alt="" srcset="" /> */}
      <div className="font text-6xl font-extrabold text-center">Don't do drugs kids</div>
      <div className="description text-center">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio, magnam sit eius at quaerat possimus esse deleniti obcaecati recusandae, autem nihil, sapiente necessitatibus ipsum in adipisci inventore ipsa iusto incidunt.</div>
      <div className="buttons flex gap-4">
        <div className="bg-red-200 h-10 w-28 flex justify-center items-center">Take a pills</div>
        <div className="bg-blue-300 h-10 w-28 flex justify-center items-center">Join discord</div>
      </div>
    </div>
    <div className="md:container md:mx-auto px-12">
      <div className="sober flex items-center">
        <div className="image max-w-2xl">
          <img src="/mrpillsgreen.png" alt="" />
        </div>
        <div>
          <div className="text-2xl font-bold">Please, don't stay <span className="text-pink-400">sober.</span></div>
          <div className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum necessitatibus quibusdam, optio ex laborum, aliquam distinctio, quod placeat autem veritatis culpa beatae expedita. Soluta provident, a saepe eum impedit doloribus.</div>
        </div>
      </div>
      <div className="about flex">
        <div>
          <div className="text-2xl font-bold"><span className="text-pink-400">About</span> eDrugs Labs</div>
          <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, reiciendis illum aspernatur maxime cum distinctio numquam atque velit repellendus eum quas quos modi ipsum ratione recusandae quibusdam. Hic, obcaecati quam.</div>
          <div className="divider"></div>
          <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, reiciendis illum aspernatur maxime cum distinctio numquam atque velit repellendus eum quas quos modi ipsum ratione recusandae quibusdam. Hic, obcaecati quam.</div>
        </div>
        <div className="image max-w-2xl">
          <img src="/mrpillsgreen.png" alt="" />
        </div>
      </div>
      <div className="roadmap">
        <div className="text-center text-2xl font-bold">Discover our roadmap</div>

        <div className="road flex flex-wrap items-center mb-8">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <img className="h-64 w-64 mx-auto border" src="/mrpillsgreen.png" alt="" />
          </div>
          <div className="w-full md:w-2/3 text-center md:text-left">
            <div className="title text-xl font-bold">eDrugs Labs</div>
            <div className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias magni eum temporibus voluptates fugiat exercitationem, sint illum asperiores atque rem sunt perspiciatis voluptatibus, quia neque in excepturi error nobis quasi.</div>
            <div className="button w-28 h-8 bg-slate-500 flex items-center justify-center rounded-sm mx-auto md:mx-0">More info</div>
          </div>
        </div>

        <div className="road flex flex-wrap items-center mb-8 md:flex-row-reverse">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <img className="h-64 w-64 mx-auto border" src="/mrpillsgreen.png" alt="" />
          </div>
          <div className="w-full md:w-2/3 text-center md:text-left">
            <div className="title text-xl font-bold">Another Title</div>
            <div className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias magni eum temporibus voluptates fugiat exercitationem, sint illum asperiores atque rem sunt perspiciatis voluptatibus, quia neque in excepturi error nobis quasi.</div>
            <div className="button w-28 h-8 bg-slate-500 flex items-center justify-center rounded-sm mx-auto md:mx-0">More info</div>
          </div>
        </div>

        <div className="road flex flex-wrap items-center mb-8">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <img className="h-64 w-64 mx-auto border" src="/mrpillsgreen.png" alt="" />
          </div>
          <div className="w-full md:w-2/3 text-center md:text-left">
            <div className="title text-xl font-bold">eDrugs Labs</div>
            <div className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias magni eum temporibus voluptates fugiat exercitationem, sint illum asperiores atque rem sunt perspiciatis voluptatibus, quia neque in excepturi error nobis quasi.</div>
            <div className="button w-28 h-8 bg-slate-500 flex items-center justify-center rounded-sm mx-auto md:mx-0">More info</div>
          </div>
        </div>

        <div className="road flex flex-wrap items-center mb-8 md:flex-row-reverse">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <img className="h-64 w-64 mx-auto border" src="/mrpillsgreen.png" alt="" />
          </div>
          <div className="w-full md:w-2/3 text-center md:text-left">
            <div className="title text-xl font-bold">Another Title</div>
            <div className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias magni eum temporibus voluptates fugiat exercitationem, sint illum asperiores atque rem sunt perspiciatis voluptatibus, quia neque in excepturi error nobis quasi.</div>
            <div className="button w-28 h-8 bg-slate-500 flex items-center justify-center rounded-sm mx-auto md:mx-0">More info</div>
          </div>
        </div>
      </div>



      <div className="ask">

        <div className="flex flex-col gap-2">
          <div className="collapse bg-base-200">
            <input type="checkbox" className="peer" />
            <div className="collapse-title bg-slate-100 text-black peer-checked:bg-slate-100 peer-checked:text-black">
              Who is the team?
            </div>
            <div className="collapse-content bg-primary text-black peer-checked:bg-slate-100 peer-checked:text-black">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores aut ex dignissimos eius reprehenderit magnam harum culpa, numquam accusantium cum quae voluptatibus voluptas explicabo dolore, nihil atque impedit magni mollitia?</p>
            </div>
          </div>

          <div className="collapse bg-base-200">
            <input type="checkbox" className="peer" />
            <div className="collapse-title bg-slate-100 text-black peer-checked:bg-slate-100 peer-checked:text-black">
              Does the team take drugs?
            </div>
            <div className="collapse-content bg-slate-100 text-black peer-checked:bg-slate-100 peer-checked:text-black">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat, mollitia exercitationem. Doloribus, animi alias? Sapiente facere quam illo doloribus blanditiis accusamus, illum vel dolore architecto iure. Odio nulla itaque nam?</p>
            </div>
          </div>

          <div className="collapse bg-base-200">
            <input type="checkbox" className="peer" />
            <div className="collapse-title bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              Why do you want to create a R2E?
            </div>
            <div className="collapse-content bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, officiis odit qui quam amet assumenda a natus doloribus consequuntur architecto. Expedita dignissimos autem exercitationem tempore, atque sequi dicta sapiente ut!</p>
            </div>
          </div>

          <div className="collapse bg-base-200">
            <input type="checkbox" className="peer" />
            <div className="collapse-title bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              How do you want to import them into the metaverse?
            </div>
            <div className="collapse-content bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporibus laudantium excepturi, cumque veritatis alias et illo ad. Exercitationem aut provident, doloremque fugiat nisi ipsum velit numquam similique magni rerum aliquam?</p>
            </div>
          </div>

          <div className="collapse bg-base-200">
            <input type="checkbox" className="peer" />
            <div className="collapse-title bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              How are exclusivities and benefits paid for holders?
            </div>
            <div className="collapse-content bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid consequatur eos harum a deserunt perferendis rerum quia beatae explicabo sed, officiis nisi illo dolor similique inventore, soluta quas. Adipisci, rerum!</p>
            </div>
          </div>

          <div className="collapse bg-base-200">
            <input type="checkbox" className="peer" />
            <div className="collapse-title bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              How much are the royalties?
            </div>
            <div className="collapse-content bg-slate-100 text-black peer-checked:bg-gray-100 peer-checked:text-black">
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit pariatur distinctio aliquam consequuntur expedita id odio commodi obcaecati repudiandae similique repellat, totam officiis, quibusdam dignissimos quos enim sapiente? Reiciendis, dicta?</p>
            </div>
          </div>

        </div>
      </div>

    </div>
    <footer className="footer p-10 bg-base-200 text-base-content">
      <aside>
        <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg>
        <p>ACME Industries Ltd.<br />Providing reliable tech since 1992</p>
      </aside>
      <nav>
        <h6 className="footer-title">Services</h6>
        <a className="link link-hover">Branding</a>
        <a className="link link-hover">Design</a>
        <a className="link link-hover">Marketing</a>
        <a className="link link-hover">Advertisement</a>
      </nav>
      <nav>
        <h6 className="footer-title">Company</h6>
        <a className="link link-hover">About us</a>
        <a className="link link-hover">Contact</a>
        <a className="link link-hover">Jobs</a>
        <a className="link link-hover">Press kit</a>
      </nav>
      <nav>
        <h6 className="footer-title">Legal</h6>
        <a className="link link-hover">Terms of use</a>
        <a className="link link-hover">Privacy policy</a>
        <a className="link link-hover">Cookie policy</a>
      </nav>
    </footer>
  </div>);
}

export default App;